// Define a type for the locales supported

import { Locale } from './i18n'

// Define a type for the structure of your dictionaries
export type Dictionaries = {
    [K in Locale]: () => Promise<any> // Replace `any` with a more specific type that matches your dictionaries' structure
}

// Define your dictionaries object with explicit types
const dictionaries: Dictionaries = {
ch: () => import('./dictionaries/ch.json').then((module) => module.default),
    ru: () => import('./dictionaries/ru.json').then((module) => module.default),
    en: () => import('./dictionaries/en.json').then((module) => module.default),
}

// Now TypeScript knows that `locale` is one of the valid keys in `dictionaries`
export const getDictionary = async (locale: Locale) => dictionaries[locale]()
