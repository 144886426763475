import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Col,Flex!=!/var/www/yakutya_front/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Col,Row!=!/var/www/yakutya_front/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Row!=!/var/www/yakutya_front/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/common/all-items-btn/all-item-btn.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/yakutya_front/src/components/common/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/common/heading/heading.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/attraction/styles/attraction-box.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/yakutya_front/src/components/page-modules/index/components/calendar-of-events/CalendarOfEvents.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/index/components/interactive-map/InteractiveMap.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/index/components/things-to-do/things.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/index/components/welcome/welcome.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/index/components/what-do-see/what-do-see.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/news/styles/blog-box.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/news/styles/news.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/yakutya_front/src/containers/interactive-map-container/InteractiveMapContainer.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/styles/tours/tour-box.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/styles/tours/tour-list.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/yakutya_front/src/components/page-modules/index/components/about-us/about.module.scss");
