'use client';

import React, { useEffect, useState } from 'react';
import Heading from '@/components/common/heading/Heading';
import { HeadingType } from '../../types/mixed.types';
import styles from '@/styles/interactive-map/interactive-map-container.module.scss';
import MobileHeadingArea from '@/components/common/mobile-heading-area/MobileHeadingArea';
import dynamic from 'next/dynamic';
import { getDictionary } from '@/dictionaries';
import { Locale } from '@/i18n';

type Props = {
    locale: Locale;
    isBgShow?: boolean;
    isHeadingShow?: boolean;
};

const InteractiveMap = dynamic(() =>
    import('@/components/page-modules/index/components/interactive-map/InteractiveMap')
);

const InteractiveMapContainer: React.FC<Props> = ({ locale, isBgShow = false, isHeadingShow = false }) => {
    const [dic, setDic] = useState<any>(null);

    useEffect(() => {
        const fetchDictionary = async () => {
            const dictionary = await getDictionary(locale);
            setDic(dictionary);
        };

        fetchDictionary();
    }, [locale]);

    if (!dic) {
        return; // Вы можете заменить это на спиннер или другой индикатор загрузки
    }

    return (
        <section className={styles.enter_wrapper}>
            {isBgShow && (
                <div className={styles.heading_wrapper}>
                    <div className="section_bg_green small" />
                    <MobileHeadingArea>
                        <Heading text={dic.InteractiveMap.title} type={HeadingType.h1} />
                    </MobileHeadingArea>
                </div>
            )}

            <div className="clear_bg reverse">
                <InteractiveMap
                    allCategory={dic.InteractiveMap.Inputs.Calendar.allCategory}
                    placeholder={dic.InteractiveMap.Inputs.Calendar.placeholder}
                    resetFilters={dic.InteractiveMap.Inputs.Calendar.resetFilters}
                    title={dic.InteractiveMap.title}
                    cls={styles.map_wrapper}
                    isHeadingShow={isHeadingShow}
                />
            </div>
        </section>
    );
};

export default InteractiveMapContainer;
