import React from "react";
import styles from "./mobile-heading-area.module.scss";
type MobileHeadingAreaType = {
  children: React.ReactNode;
};
const MobileHeadingArea: React.FC<MobileHeadingAreaType> = ({ children }) => {
  return <div className={styles.header_wrapper}>{children}</div>;
};

export default MobileHeadingArea;
